<template>
  <button

 



    @click="selectedItemEvent"
    class="btn-cat-item h-[30px] shrink-0 !rounded-full border py-1.5 px-3.5 text-xs1 font-medium outline-none transition-opacity duration-200    opacity-90"
    :class="{' active-item':isActive}"

  >
    {{ category.name }}
  </button>
</template>

<script>

  import { BButton
} from 'bootstrap-vue'
export default {
  components:{
    BButton
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    selectedItemEvent() {
      this.$emit('selectedItem',this.category);
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>

<!--     :class="[
  'h-30',
  'shrink-0',
  'rounded-full',
  'border',
  'py-1.5',
  'px-3.5',
  'text-xs',
  'font-medium',
  'outline-none',
  {
    'border-dark': isActive,
    'border-light-500': !isActive,
    'bg-dark-100': isActive,
    'bg-light-400': !isActive,
    'text-light-100': isActive,
    'text-dark-100': !isActive,
    'transition-opacity': isActive,
    'duration-200': isActive,
    'hover:opacity-90': isActive,
    'focus:opacity-90': isActive,
    'dark:border-light': isActive,
    'dark:bg-light': isActive,
    'dark:text-dark-100': isActive,
    'hover:bg-light-500': !isActive,
    'dark:border-dark-500': !isActive,
    'dark:bg-dark-400': !isActive,
    'dark:text-light-100': !isActive,
    'hover:dark:bg-dark-500': !isActive,
    'hover:dark:text-light': !isActive,
  }
]" -->