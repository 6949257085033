<template>
  <div class="ecommerce-application">
    <!-- <CategoryFilter :categories="categories" /> -->
    <!--   <b-card class="p-0">
    <b-card-body class="p-0"> -->

    <div
      class="sticky1 fixed-top-v2 navbar-shadow top-16 z-201 !flex min-h-64 w-full overflow-hidden border-b border-light-400 bg-light-100 dark:border-dark-300 dark:bg-dark-100 px-md-2 py-sm-2 px-md-2 px-lg-75 px-xl-75"
    >
      <b-skeleton-wrapper :loading="catLoading">
        <swiper
          class="swiper-centered-slides-21 p-0"
          :options="swiperOptions"
          :dir="'rtl'"
        >
          <swiper-slide key="all">
            <CategoryItem
              :category="{ id: null, name: 'الكل ' }"
              :isActive="null == filters.category_id"
              @selectedItem="
                (newVal) => {
                  filters.category_id = null;
                }
              "
            />
          </swiper-slide>
          <!--   class="rounded swiper-shadow py-1 px-3 d-flex" -->
          <swiper-slide v-for="data in categories" :key="data.id">
            <CategoryItem
              :category="data"
              :isActive="data.id == filters.category_id"
              @selectedItem="
                (newVal) => {
                  (filters.category_id = newVal.id), updateSubCatagory(newVal);
                }
              "
            />
          </swiper-slide>

          <!-- Add Arrows -->
          <div
            v-if="categories.length > 6"
            slot="button-prev"
            class="swiper-button-prev"
          ></div>
          <div
            v-if="categories.length > 6"
            slot="button-next"
            class="swiper-button-next"
          ></div>
        </swiper>

        <!--     <div class="mt-1 flex items-start  overflow-hidden"  v-if="sub_categories.length>0">
      <div class=" flex w-full   overflow-x-auto scroll-smooth pb-2">

     <CategoryItem
       v-for="subdata in sub_categories"
        :key="subdata.value"
          :category="subdata"
          :isActive="subdata.id==filters.sub_category_id"
         @selectedItem="newVal=>filters.sub_category_id=newVal.id"
        /> 

      </div>

    </div> -->
      </b-skeleton-wrapper>
    </div>
    <!--    <b-row>
      <b-col md="12"> 
        <b-form-group class="group_button_sub_catagory">
          <b-form-checkbox-group
           v-if="sub_categories.length>0"
            v-model="selectedCheckbox"
            :options="sub_categories"
            name="buttons-1"
            buttons
             style="width: 100%;    padding: 11px;"
            button-variant="outline-secondary"
          />

 

        </b-form-group>
      </b-col>
</b-row> -->
    <!--     <swiper
      class="swiper-centered-slides-21 p-1"
      :options="swiperOptions"
      :dir="'rtl'"
      v-if="sub_categories.length>0"
    >  
      <swiper-slide
        v-for="subdata in sub_categories"
        :key="subdata.value"
       
      >

     <CategoryItem
          :category="subdata"
          :isActive="subdata.id==filters.sub_category_id"
         @selectedItem="filters.sub_category_id=subdata.id"
        /> 
 
      </swiper-slide> 
      <div
        slot="button-prev"
        class="swiper-button-next"
      />
          <div
        slot="button-next"
        class="swiper-button-prev"
      />
    </swiper> -->

    <!-- </b-card-body>
</b-card>  -->

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="اكتب هنا"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
    <div v-if="catLoading" class="loading_page spinner-primary text-center">
      <b-spinner label="Loading..." />
    </div>
    <!--       <b-spinner
      v-if="catLoading"
      type="grow"
       style="width: 3rem; height: 3rem;"
      label="Loading..."
    /> -->

    <!-- Prodcuts -->
    <section :class="itemView" v-if="!catLoading">
      <b-skeleton-table
        :rows="10"
        :columns="6"
        v-if="prodLoading"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>

      <b-card
        v-for="product in products"
        :key="`product_card_${product.variation_id}`"
        class="ecommerce-card"
        style="grid-template-columns: unset"
        no-body
      >
        <b-card-body>
          <b-row class="my-2">
            <!-- Left: Product Image Container -->
            <b-col
              cols="12"
              md="2"
              class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-img
                  :src="product.image_url"
                  :alt="`Image of ${product.name}`"
                  class="product-img"
                  style="width: 100px"
                  fluid
                />
              </div>
            </b-col>

            <!-- Right: Product Details -->
            <b-col cols="12" md="9">
              <!-- Product Name -->
              <h4>{{ product.name }}</h4>

              <!--  <b-card-text v-html="product.description"></b-card-text> -->
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12" xs="12" sm="12">
              <!-- <div class="table-responsive">
  <table class="table table-striped  text-center table-bordered dataTable no-footer" style="text-align: center;width:100%;    white-space: nowrap !important;" border="1" v-if="product.suppliers.length>0"  v-for="(supplier,index) in product.suppliers" :key="supplier.id">
 
    <tbody>
        <tr>
 
            <th style="width: 14%;">المورد</th>
            <th style="width: 14%;"> الكمية المتوفرة</th>


               <th style="width: 12%;"  v-for="item in supplier.products" :key="item.id">{{item.from_qty}} -  {{item.to_qty}}</th>

          
 
        </tr>
        <tr>
            <td style="width: 14%;">{{supplier.name}}</td>
            <td style="width: 14%;">♾️</td>
  
            <td style="width: 12%;" v-for="item in supplier.products" :key="item.id"> {{item.price_format}}</td>
 

                       
        </tr>
     
    </tbody>
</table>  
</div> -->

              <b-table-simple
                v-if="!prodLoading"
                hover
                bordered
                responsive
                class="rounded-bottom"
                v-for="(supplier, index) in product.suppliers"
                :key="`simple_${supplier.id}`"
              >
                <b-tr>
                  <b-th>المورد</b-th>
                  <b-th> الكمية المتوفرة</b-th>
                  <b-th
                    v-for="item in supplier.items"
                    :key="`to_qty_to_qty${item.id}`"
                    >{{ item.from_qty }} - {{ item.to_qty }}</b-th
                  >
                  <!-- <b-th>التقييمات </b-th> -->
                  <b-th>
                    <b-button
                      variant="light"
                      tag="a"
                      class="btn-wishlist"
                      @click="toggleProductInWishlist(supplier)"
                    >
                      <feather-icon
                        icon="HeartIcon"
                        class="mr-50"
                        :class="{ 'text-danger': product.isInWishlist }"
                      />
                      <span>Wishlist</span>
                    </b-button>
                  </b-th>
                </b-tr>

                <b-tr>
                  <b-td>
                    <b-link
                      class="text-body"
                      :to="{
                        name: 'cataloge-product-details',
                        params: { slug: supplier.uuid },
                      }"
                    >
                      {{ supplier.name }}
                    </b-link>
                  </b-td>
                  <b-td>♾️</b-td>
                  <b-td
                    v-for="item in supplier.items"
                    :key="`price_format${item.id}`"
                  >
                    {{ item.price_format }}</b-td
                  >

                  <!-- <b-td>&nbsp;</b-td> -->
                  <b-td>
                    <b-button
                      variant="primary"
                      tag="a"
                      class="btn-cart"
                      @click="handleCartActionClick(supplier)"
                    >
                      <feather-icon icon="ShoppingCartIcon" class="mr-50" />

                      <span>{{
                        supplier.isInCart == true ? "عرض" : "اضافة"
                      }}</span>
                    </b-button>
                  </b-td>
                </b-tr>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </section>

    <!-- Pagination -->
    <section class="hidden">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProducts"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <!--     <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal> -->
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BCarousel,
  BCarouselSlide,
  BFormCheckboxGroup,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";

import {
  BSpinner,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonTable,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { watch, ref } from "@vue/composition-api";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import FilterSidebar from "./FilterSidebar.vue";
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
  useShopRemoteData,
  useCategoriesData,
} from "./useShop";
import { useEcommerceUi, isProductInCart } from "../useCataloge";
//import CategoryFilter from '../../category-filter.vue'
import CategoryItem from "./parts/CategoryItem.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    //CategoryFilter,
    BSpinner,
    CategoryItem,

    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    Swiper,
    SwiperSlide,
    // SFC
    FilterSidebar,

    BCarousel,
    BCarouselSlide,

    BFormCheckboxGroup,
    BFormGroup,
    BFormCheckbox,

    BSkeletonWrapper,
    BSkeleton,
    BSkeletonTable,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  },
  data() {
    return {
      swiperData: [
        { icon: "GithubIcon", text: "Getting Started" },
        { icon: "FacebookIcon", text: "Pricing & Plans" },
        { icon: "TwitterIcon", text: "Sales Question" },
        { icon: "InstagramIcon", text: "Usage Guides" },
        { icon: "FacebookIcon", text: "Pricing & Plans" },
      ],
      swiperOptions: {
        //slidesPerView: 6,
        slidesPerView: "auto",
        centeredSlides: false,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1024: {
            slidesPerView: "auto",
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        },
      },
    };
  },
  setup() {
    const { filters, filterOptions, sortBy, sortByOptions } =
      useShopFiltersSortingAndPagination();

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();

    const { itemView, itemViewOptions, totalProducts } = useShopUi();

    const { products, fetchProducts } = useShopRemoteData();
    const { categories, fetchCategories } = useCategoriesData();
    const sub_categories = ref([]);
    const selectedCheckbox = ref([]);
    const category_selected = ref(0);
    const catLoading = ref(true);
    const prodLoading = ref(true);
    const selectedButtons = ref([]);
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
    const updateSubCatagory = (data) => {
      // console.log('selectedItem',data)
      sub_categories.value = data.sub_categories ? data.sub_categories : [];
      //   if(data.sub_categories){
      // sub_categories.value=data.sub_categories.map(n=>{
      //        return {
      //          text:n.name,
      //          value:n.id,
      //        }
      //     })
      //   }
    };

    const fetchApiCategories = () => {
      catLoading.value = true;
      fetchCategories().then((response) => {
        categories.value = response.data;
        catLoading.value = false;

        fetchShopProducts();
      });
    };
    fetchApiCategories();
    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      products.value = [];
      prodLoading.value = true;
      fetchProducts({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
        category_id: filters.value.category_id,
        sub_category_id: filters.value.sub_category_id,
      }).then((response) => {
        products.value = response.data;
        totalProducts.value = products.value.length;
        prodLoading.value = false;
      });
    };

    //

    watch(
      [filters, sortBy],
      () => {
        fetchShopProducts();
      },
      {
        deep: true,
      }
    );

    return {
      // useShopFiltersSortingAndPagination
      catLoading,
      prodLoading,
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,
      categories,
      sub_categories,
      selectedCheckbox,
      selectedButtons,
      category_selected,
      updateSubCatagory,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
      isProductInCart,
    };
  },
};
</script>

<style lang="scss">
@import "~@resources/scss/base/pages/app-ecommerce.scss";
@import "~@resources/scss/vue/libs/swiper.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="css" scoped>
[dir]
  .group_button_sub_catagory
  .btn-group-toggle
  > .btn.btn-outline-secondary {
  border: 1px solid #82868b !important;
  margin-right: 10px !important;
  border-radius: 0.358rem !important;
}
.group_button_sub_catagory label.btn.btn-outline-secondary ::v-deep {
  border: 1px solid #82868b !important;
  margin-right: 10px !important;
  border-radius: 0.358rem !important;
}

.swiper-centered-slides-21.swiper-container .swiper-slide {
  font-weight: 500;
  height: auto;
  width: auto !important;
}

.bg-light-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249/1);
  background-color: rgb(252 252 252);
}
.border-light-400 {
  --tw-border-opacity: 1;
  border-color: rgb(230 230 230 / var(--tw-border-opacity));
}
.dark-layout .bg-light-100 {
  --tw-bg-opacity: 1;
  background-color: #252525;
}
.dark-layout .border-light-400 {
  --tw-border-opacity: 1;
  border-color: rgb(24 24 24 / var(--tw-border-opacity));
}
.dark-layout .bg-dark-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 24 / var(--tw-bg-opacity));
}
.dark-layout .border-dark-100 {
  --tw-border-opacity: 1;
  border-color: rgb(24 24 24 / var(--tw-border-opacity));
}

.border-b,
.border-y {
  border-bottom-width: 1px;
}
.w-full {
  width: 100%;
}
.z-20 {
  z-index: 20;
}
.sticky {
  position: sticky;
}

.h-\[30px\] {
  height: 30px;
}
.duration-200 {
  transition-duration: 0.2s;
}
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.text-light-100 {
  --tw-text-opacity: 1;
  color: rgb(249 249 249 / var(--tw-text-opacity));
}
.font-medium {
  font-weight: 500;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}
.dark-layout .bg-dark-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 24 / var(--tw-bg-opacity));
}
.dark-layout .border-dark-100 {
  --tw-border-opacity: 1;
  border-color: rgb(24 24 24 / var(--tw-border-opacity));
}
.border {
  border-width: 1px;
}
.\!rounded-full {
  border-radius: 9999px !important;
}
.flex-shrink-0,
.shrink-0 {
  flex-shrink: 0;
}

.border-light-500 {
  --tw-border-opacity: 1;
  border-color: rgb(218 218 218 / var(--tw-border-opacity));
}

.opacity-90:hover {
  opacity: 0.9;
}

.border {
  border-width: 1px;
}

.overflow-hidden {
  overflow: hidden;
}
.items-start {
  align-items: flex-start;
}
.flex {
  display: flex;
}
.-mb-4 {
  margin-bottom: -1rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}
.scroll-smooth {
  scroll-behavior: smooth;
}
.overflow-x-auto {
  overflow-x: auto;
}
.gap-3 {
  gap: 0.75rem;
}
.w-full {
  width: 100%;
}

.-mb-7 {
  margin-bottom: -1.75rem;
}

.dark-layout .bg-dark-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 24 / var(--tw-bg-opacity));
}

.dark-layout .border-dark-300 {
  --tw-border-opacity: 1;
  border-color: rgb(42 42 42 / var(--tw-border-opacity));
}

.dark-layout .dark\:active-text-light {
  text-shadow: 0 0 #e6e6e6;
}

.dark-layout .text-light-400 {
  --tw-text-opacity: 1;
  color: rgb(230 230 230 / var(--tw-text-opacity));
}
.dark-layout .bg-dark-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(50 50 50 / var(--tw-bg-opacity));
}
.bg-light-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(210 210 210 / var(--tw-bg-opacity));
}

.bg-light-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(237 237 237 / var(--tw-bg-opacity));
}

.dark-layout .bg-dark-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(42 42 42 / var(--tw-bg-opacity));
}

.dark-layout .bg-dark-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(62 62 62 / var(--tw-bg-opacity));
}
.active-text-dark {
  text-shadow: 0 0 #181818;
}
.dark-layout .active-text-light {
  text-shadow: 0 0 #e6e6e6;
}
.dark-layout .text-light-400 {
  --tw-text-opacity: 1;
  color: rgb(230 230 230 / var(--tw-text-opacity));
}

.btn-cat-item {
  --tw-text-opacity: 1;
  color: rgb(24 24 24 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(230 230 230 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(218 218 218 / var(--tw-border-opacity));
}

.btn-cat-item.active-item,
.btn-cat-item:hover {
  --tw-border-opacity: 1;
  border-color: rgb(24 24 24 / var(--tw-border-opacity));
  opacity: 0.9;
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 24 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(249 249 249 / var(--tw-text-opacity));
}

.dark-layout .btn-cat-item {
  --tw-border-opacity: 1;
  border-color: rgb(62 62 62 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(249 249 249 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(50 50 50 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(62 62 62 / var(--tw-border-opacity));
}

.dark-layout .btn-cat-item.active-item,
.dark-layout .btn-cat-item:hover {
  --tw-text-opacity: 1;
  color: rgb(24 24 24 / var(--tw-text-opacity));

  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.table th,
.table td {
  padding: 5px 6px;
  text-align: center;
  min-width: 100px;
}
.fixed-top-v2 {
  position: fixed;
  top: 62px;
  z-index: 996;
}
[dir="rtl"] .fixed-top-v2 {
  right: 200px;
}
[dir="rtl"] .vertical-layout.vertical-menu-modern.menu-collapsed .fixed-top-v2 {
  right: 80px;
}

@media (max-width: 1201px) {
  [dir="rtl"] .vertical-layout .fixed-top-v2 {
    right: 0;
  }
  .fixed-top-v2 {
    padding: 10px 10px;
  }
}

html[dir] .navbar-sticky .app-content {
  padding-top: calc(10.75rem) !important;
}

.navbar-shadow {
  -webkit-box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

[dir] .header-navbar.navbar-shadow {
  -webkit-box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  box-shadow: 0 4px 0px 0 rgba(34, 41, 47, 0.1);
}
.ecommerce-application {
  margin-bottom: 100px;
}
.ecommerce-application .list-view .ecommerce-card {
  overflow: unset !important;
  display: block !important;
  grid-template-columns: unset !important;
}
[dir] .ecommerce-application .ecommerce-card:hover {
  overflow: unset !important;
}
.table-responsive {
  padding-bottom: 10px;
}

@media (max-width: 1201px) {
  .btn-cat-item {
    padding-top: 5px;
    padding-bottom: 10px;
    font-size: 11px;
  }
}
</style>
